import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import WhyHRProschooseVC from "../components/WhyHRProschooseVC"
import AmazonCatalogCarousal from "../components/AmazonCatalogCarousal"
import { Helmet } from "react-helmet"
import HubspotForm from "react-hubspot-form"

function CostSaving({ location }) {
  const AmazonCatalogCrousalDetails = [
    {
      slideImage: "amazon_cost_saving-Travel.webp",
      slideDescription: "Travel",
    },
    {
      slideImage: "amazon_cost_saving-Fashion.webp",
      slideDescription: "Apparels & Fashion",
    },
    {
      slideImage: "amazon_cost_saving-Home.webp",
      slideDescription: "Electronic & Accessories ",
    },
    {
      slideImage: "amazon_cost_saving-hobby.png",
      slideDescription: "Home Appliance ",
    },
    {
      slideImage: "amazon_cost_saving-Home.webp",
      slideDescription: "Hobbies & Instruments",
    },
  ]

  return (
    <Layout location={location}>
      <Helmet>
        <title>Service Awards Budget - V1 | Vantage Circle </title>

        {/* <meta name="description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
          <meta property="twitter:title" content="Rewards and Recognition Platform V1 | Vantage Circle" />
          <meta name= "robots" content= "noindex" />
                  
          <meta property="twitter:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
                  
          <meta property="og:type" content="website"/>
                  
          <meta property="og:url" content="https://www.vantagecircle.com/rewards-and-recognition-platform-v1/"/>

          <meta property="og:site_name" content="Vantage Circle"/>
          <meta property="og:title" content="Rewards and Recognition Platform V1 | Vantage Circle" />

          <meta property="og:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work" /> */}
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/service-awards-budget-v1/"
        ></link>
      </Helmet>
      <section
        id="home"
        className="w-11/12 2xl:max-w-7xl mx-auto mb-5 rounded-xl flex flex-col place-content-center relative bg-purple-80 overflow-x-hidden xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0 mb-10"
      >
        <div className="w-full grid xl:grid-cols-2 justify-center items-start z-10 lg:px-10 relative">
          <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 py-10 xl:py-20">
            <div className="my-2 flex justify-center items-center">
              <p className="text-center md:text-left">SERVICE AWARDS</p>
            </div>
            <h1 className="lg:pr-3 xl:w-full homepage-heading text-center lg:text-left relative">
              Exceptional{" "}
              <span className="text-orange">
                Service <br /> Awards
              </span>{" "}
              at half the price <br /> of competitors
            </h1>
            <p
              className="my-8 text-indigo-100 text-center lg:text-left w-full xl:w-11/12 text-black"
              style={{ lineHeight: "2rem" }}
            >
              Maximize the impact of your service awards program while
              minimizing costs. Vantage Circle offers premium recognition
              solutions at 50% less than the competition.
            </p>
            <div className="">
              <a
                href="#form"
                className="vc-new-orange-btn-rounded-full text-purple-100 lato rounded-full mt-3 md:mt-0"
              >
                Request Demo
              </a>
            </div>
          </div>
          <div className="flex justify-center xl:justify-end items-center h-full">
            <picture className="z-10 relative mx-auto transform scale-100 xl:scale-125 xl:translate-y-20 xl:-translate-x-10 ">
              <source
                srcSet="https://res.cloudinary.com/vantagecircle/image/upload/w_1000/v1724132988/vantagecircle/amazon-cost-saving/amazon_cost_saving-hero.png"
                media="(min-width: 1023px)"
                type="image/webp"
                alt="vantage-rewards-social-recognition"
              />
              <source
                srcSet="https://res.cloudinary.com/vantagecircle/image/upload/w_600/v1724132988/vantagecircle/amazon-cost-saving/amazon_cost_saving-hero.png"
                media="(min-width: 640px)"
                type="image/webp"
                alt="vantage-rewards-social-recognition"
              />
              <img
                className="z-10 relative mx-auto"
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1724132988/vantagecircle/amazon-cost-saving/amazon_cost_saving-hero.png"
                srcSet="https://res.cloudinary.com/vantagecircle/image/upload/w_400/v1724132988/vantagecircle/amazon-cost-saving/amazon_cost_saving-hero.png"
                alt="vantage-rewards-social-recognition"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="mx-4 lg:mx-0">
        <div className="max-w-5xl border border-gray-900 mx-auto w-full rounded-xl ">
          <div className="flex flex-col xl:flex-row my-8 px-2">
            <div className="w-full xl:w-5/12 flex items-center xl:ml-8">
              <h1 className="text-2xl lg:text-3xl text-center xl:text-left w-full mb-4 xl:mb-0 ">
                Winner of top global awards
              </h1>
            </div>
            <div className="w-full xl:w-7/12 grid grid-cols-4 gap-x-2 awrd-logos">
              <img
                className="w-28 h-26"
                src="https://res.cloudinary.com/vantagecircle/image/upload/q_auto/gatsbycms/uploads/2024/07/g2-12.png"
                alt="leader"
              />
              <img
                className="w-28 h-26"
                src="https://res.cloudinary.com/vantagecircle/image/upload/q_auto/gatsbycms/uploads/2024/05/Gartner-badge.webp"
                alt="Gartner-badge"
              />
              <img
                className="w-28 h-26"
                src="https://res.cloudinary.com/vantagecircle/image/upload/q_auto/gatsbycms/uploads/2024/05/Brandon-hall-gold.webp"
                alt="Brandon-hall-gold"
              />
              <img
                className="w-28 h-26"
                src="https://res.cloudinary.com/vantagecircle/image/upload/q_auto/gatsbycms/uploads/2024/05/Bakers-dozen-gold.webp"
                alt="hro"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="caseStudy">
        <div className="max-w-7xl mx-auto bg-purple-80 py-10 xl:py-28 xl:pb-80 mt-10 ">
          <div>
            <h1 className="text-2xl lg:text-3xl text-center px-6 xl:px-0">
              <span className="text-orange">Clients saved 50%</span> on their
              service award budget by switching to <br />
              Vantage Circle's{" "}
              <span className="text-orange">Amazon-Powered Catalog</span> .
            </h1>
          </div>
          <div className="mt-10 lg:mt-20 rnrCarousel">
            <AmazonCatalogCarousal
              RnRSlideDetails={AmazonCatalogCrousalDetails}
            />
          </div>
          <div className="mx-10 flex flex-col  justify-between items-center space-y-4 my-4">
            <p className="text-sm text-gray-200 m-0 text-center xl:text-left">
              *At the time of direct comparison with competitors
            </p>
            <Link to="#form">
              <button className="border border-orange text-orange rounded-2xl px-4 py-2  transform hover:-translate-y-1 transition hover:duration-500 hover:ease-in-out">
                Download Case Study
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="xl:-mt-64" id="form">
        <div className="max-w-6xl mx-auto grid xl:grid-cols-2 bg-indigo-100 pt-10 pb-20  xl:py-20 xl:rounded-xl relative z-1">
          <div className=" grid px-10 xl:px-0 xl:pl-20">
            <div>
              <h1 className="text-2xl xl:text-4xl text-left text-white">
                Unlock savings beyond Service Awards
              </h1>
            </div>
            <div className="mt-5 ">
              <div className="flex w-full items-center">
                <img
                  className="w-12 h-12"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1724132987/vantagecircle/amazon-cost-saving/amazon_cost_saviing_-_form_-ico1.webp"
                  alt=""
                  width={100}
                  height={100}
                />
                <p className="pl-5 m-0 text-white">
                  Save on you annual rewards budget
                </p>
              </div>
              <hr
                style={{ height: "1px" }}
                className="w-11/12 bg-gray-50 mt-5"
              />
            </div>
            <div className="mt-5 ">
              <div className="flex w-full items-center">
                <img
                  className="w-12 h-12"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1724132986/vantagecircle/amazon-cost-saving/amazon_cost_saviing_-_form_-ico2.webp"
                  alt=""
                  width={100}
                  height={100}
                />
                <p className="pl-5 m-0 text-white">Save on your platform fee</p>
              </div>
              <hr
                style={{ height: "1px" }}
                className="w-11/12 bg-gray-50 mt-5"
              />
            </div>
            <div className="mt-5 ">
              <div className="flex w-full items-center">
                <img
                  className="w-12 h-12"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1724132987/vantagecircle/amazon-cost-saving/amazon_cost_saviing_-_form_-ico3.webp"
                  alt=""
                  width={100}
                  height={100}
                />
                <p className="pl-5 m-0 text-white">
                  Save on your integration costs
                </p>
              </div>
              <hr
                style={{ height: "1px" }}
                className="w-11/12 bg-gray-50 mt-5"
              />
            </div>
            <div className="mt-5 ">
              <div className="flex w-full items-center">
                <img
                  className="w-12 h-12"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1724132986/vantagecircle/amazon-cost-saving/amazon_cost_saviing_-_form_-ico4.webp"
                  alt=""
                  width={100}
                  height={100}
                />
                <p className="pl-5 m-0 text-white">
                  Save on your employee surveys, wellness etc.
                </p>
              </div>
              <hr
                style={{ height: "1px" }}
                className="w-11/12 bg-gray-50 mt-5"
              />
            </div>
            <div className="mt-5 ">
              <div className="flex w-full items-center">
                <img
                  className="w-12 h-12"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1724132986/vantagecircle/amazon-cost-saving/amazon_cost_saviing_-_form_-ico5.webp"
                  alt=""
                  width={100}
                  height={100}
                />
                <p className="pl-5 m-0 text-white">
                  Save on gift card transaction fees/surcharge
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col w-full h-full justify-center items-center xl:items-end mt-10 xl:mt-0  xl:pr-8 ">
              <div class="flex flex-col w-5/6 cost-saving pl-6 pr-4 border border-gray-darklight rounded-xl ">
                <h1 className="my-4 xl:my-6 text-white">
                  Get the complete Case Study with a <br /> Personalized Product
                  Demo
                </h1>
                <HubspotForm
                  className="w-full"
                  portalId="6153052"
                  formId="04dfc315-3df3-47ad-a9f0-650cef1a92df"
                  onSubmit={() => console.log("Submit!")}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
                <p className="text-center text-base text-white w-full">
                  Read our{" "}
                  <Link
                    className="hover:underline"
                    href="https://www.vantagecircle.com/privacy-policy/"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="amazon">
        <div className="rounded-xl lg:flex bg-white p-5 lg:p-7 mt-10 max-w-7xl mx-auto">
          <div className="lg:w-6/12 my-auto lg:mr-3 lg:px-5">
            <div className="w-full flex justify-center xl:justify-start">
              <img
                className="mb-4"
                height={50}
                width={250}
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1724235636/vantagecircle/amazon-cost-saving/amazon-business.webp"
              />
            </div>
            <h2 className="homepage-div-heading py-3 text-center lg:text-left">
              Our exclusive partnership with Amazon gives you a better Service
              Award Catalog at upto 50% lesser price
            </h2>
            <div className="mt-4 lg:mt-8 flex justify-center lg:justify-start ">
              <a
                href="#form"
                className="vc-new-orange-btn-rounded-full text-purple-100 lato rounded-full mt-3 md:mt-0 text-center lg:text-left"
              >
                Request Demo
              </a>
            </div>
          </div>
          <div className="lg:w-6/12 lg:ml-3 p-6">
            <picture>
              <source
                className="rounded-lg mx-auto w-full"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1724132986/vantagecircle/amazon-cost-saving/amazon_cost_saving-Business.webp"
                media="(min-width: 640px)"
                type="image/webp"
                alt="Vantage Perks"
                width="500"
              />
              <img
                className="rounded-lg mx-auto w-full"
                src="https://res.cloudinary.com/vantagecircle/image/upload/w_400/h_500/v1724132986/vantagecircle/amazon-cost-saving/amazon_cost_saving-Business.webp"
                alt="Vantage Perks"
                width={400}
                height={500}
              />
            </picture>
          </div>
        </div>
      </section>
      <section
        className="w-full flex flex-col place-items-center py-10 xl:py-16 px-2 xl:px-0 bg-purple-80"
        id="products"
      >
        <h2 className="lg:pr-3 w-full md:w-4/6 xl:max-w-5xl mx-auto homepage-section-heading text-center px-2">
          Our suite of <span className="text-orange">solutions</span> is
          designed to bring maximum ROl with minimal cost
        </h2>
        <p className="text-gray-500 text-base md:text-lg xl:text-2xl py-3 text-center px-3 hidden">
          Our SaaS products are powerful alone but stronger together
        </p>
        <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-6 justify-center items-center max-w-7xl mx-auto my-2 xl:pt-6 px-6 xl:px-0 2xl:px-6">
          {/* Product Block 1 */}
          <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
            <img
              decoding="async"
              loading="lazy"
              className="mx-auto pb-3"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomeRewards-3.webp"
              alt="Homepage-Rewards-1"
              width="300"
              height="231"
            />
            <h3 className="lato w-5/6 mx-auto text-center">
              Employee Rewards and Recognition platform
            </h3>
          </div>

          {/* Product Block 2 */}
          <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
            <img
              decoding="async"
              loading="lazy"
              className="mx-auto pb-3"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomeVfit-3.webp"
              alt="Homepage-vfit-1"
              width="300"
              height="231"
            />
            <h3 className="lato w-5/6 mx-auto text-center">
              Employee Wellness <br /> platform
            </h3>
          </div>

          {/* Product Block 3 */}
          <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
            <img
              decoding="async"
              loading="lazy"
              className="mx-auto pb-3"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomePulse-3.webp"
              alt="Homepage-pulse-1"
              width="300"
              height="231"
            />
            <h3 className="lato w-5/6 mx-auto text-center">
              Employee Feedback <br /> platform
            </h3>
          </div>

          {/* Product Block 4 */}
          <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
            <img
              decoding="async"
              loading="lazy"
              className="mx-auto pb-3"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomePerks-3.webp"
              alt="Homepage-perks-1"
              width="300"
              height="231"
            />
            <h3 className="lato w-5/6 mx-auto text-center">
              Corporate Discounts <br /> platform
            </h3>
          </div>
        </div>
        <div className="mt-4 lg:mt-8 flex justify-center lg:justify-start ">
          <a
            href="#form"
            className="vc-new-orange-btn-rounded-full text-purple-100 lato rounded-full mt-3 md:mt-0 text-center lg:text-left"
          >
            Request Demo
          </a>
        </div>
      </section>
      <section className="mt-10">
        <div className="max-w-7xl mx-auto ">
          <h1 className="text-2xl xl:text-4xl mb-4 xl:mb-8 w-full text-center">
            Additional features
          </h1>
          <WhyHRProschooseVC />
          <div className="rounded-xl shadow bg-white lg:grid grid-cols-2 max-w-7xl lg:mx-auto mt-2 mb-10 lg:my-0 mx-5">
            <div className="p-5 flex items-center">
              <div className="w-1/6 flex justify-center mr-5 lg:mr-0">
                <svg
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="23" cy="23" r="23" fill="#53539C" />
                  <g clip-path="url(#clip0_825_22)">
                    <path
                      d="M30.4443 31.608C29.632 31.608 28.9352 32.1101 28.6469 32.8202H26.9533V34.2748H28.6469C28.9352 34.9848 29.632 35.487 30.4443 35.487C31.5138 35.487 32.3838 34.6169 32.3838 33.5475C32.3838 32.478 31.5138 31.608 30.4443 31.608ZM33.8384 27.4866C33.5305 27.4864 33.2269 27.56 32.9533 27.7012L31.1524 26.2005C31.0217 26.0916 30.8569 26.032 30.6868 26.032H26.9533V27.4866H30.4234L32.0024 28.8024C31.9339 29.0032 31.8989 29.2139 31.8989 29.4261C31.8989 30.4955 32.769 31.3656 33.8384 31.3656C34.9079 31.3656 35.7779 30.4955 35.7779 29.4261C35.7779 28.3566 34.9079 27.4866 33.8384 27.4866ZM33.8384 21.4257C33.0261 21.4257 32.3293 21.9278 32.041 22.6379H26.9533V24.0925H32.041C32.3293 24.8025 33.0261 25.3047 33.8384 25.3047C34.9079 25.3047 35.7779 24.4346 35.7779 23.3652C35.7779 22.2958 34.9079 21.4257 33.8384 21.4257ZM31.1524 20.5298L32.9533 19.0291C33.2269 19.1704 33.5305 19.244 33.8384 19.2438C34.9079 19.2438 35.7779 18.3737 35.7779 17.3043C35.7779 16.2349 34.9079 15.3648 33.8384 15.3648C32.769 15.3648 31.8989 16.2349 31.8989 17.3043C31.8989 17.5225 31.9357 17.7321 32.0024 17.9279L30.4234 19.2438H26.9533V20.6984H30.6868C30.8569 20.6984 31.0217 20.6388 31.1524 20.5298ZM30.4443 15.1224C31.5138 15.1224 32.3838 14.2523 32.3838 13.1829C32.3838 12.1135 31.5138 11.2434 30.4443 11.2434C29.632 11.2434 28.9352 11.7455 28.6469 12.4556H26.9533V13.9102H28.6469C28.9352 14.6202 29.632 15.1224 30.4443 15.1224ZM18.2256 23.3652C18.2256 26.1724 20.5095 28.4563 23.3167 28.4563C24.0971 28.4563 24.8369 28.2796 25.4987 27.9644V18.7659C24.8369 18.4508 24.0971 18.274 23.3167 18.274C20.5095 18.274 18.2256 20.5579 18.2256 23.3652Z"
                      fill="white"
                    />
                    <path
                      d="M24.771 11.001H21.8617C21.4601 11.001 21.1344 11.3266 21.1344 11.7283V12.675C19.7665 12.9543 18.465 13.4935 17.3003 14.2634L16.6307 13.5938C16.3466 13.3098 15.8861 13.3098 15.6021 13.5938L13.545 15.6509C13.261 15.9349 13.261 16.3955 13.545 16.6795L14.2146 17.3491C13.4447 18.5138 12.9055 19.8154 12.6262 21.1833H11.6795C11.2778 21.1833 10.9521 21.5089 10.9521 21.9106V24.8198C10.9521 25.2215 11.2778 25.5471 11.6795 25.5471H12.6262C12.9055 26.915 13.4447 28.2166 14.2146 29.3813L13.5449 30.0509C13.4086 30.1873 13.332 30.3723 13.332 30.5652C13.332 30.758 13.4086 30.943 13.5449 31.0794L15.6021 33.1366C15.8861 33.4206 16.3466 33.4206 16.6307 33.1366L17.3003 32.467C18.465 33.2368 19.7666 33.776 21.1344 34.0554V35.0021C21.1344 35.4038 21.4601 35.7294 21.8617 35.7294H24.771C25.1726 35.7294 25.4983 35.4038 25.4983 35.0021V29.5365C24.8155 29.7787 24.0811 29.9109 23.3164 29.9109C19.707 29.9109 16.7706 26.9745 16.7706 23.3652C16.7706 19.7559 19.707 16.8194 23.3164 16.8194C24.0811 16.8194 24.8155 16.9517 25.4983 17.1938V11.7283C25.4983 11.3266 25.1726 11.001 24.771 11.001Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_825_22">
                      <rect
                        width="24.8254"
                        height="24.8254"
                        fill="white"
                        transform="translate(10.9521 10.9524)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="w-5/6">
                <h2 className="py-1">Hassle-free integration</h2>
                <p className="text-base">
                  Seamless integration with HCM/HRIS/SSO platforms to make
                  employee recognition efficient
                </p>
              </div>
            </div>
            <div className="p-6 bg-indigo-100 rounded-b-xl lg:rounded-bl-none lg:rounded-r-xl relative">
              <p className="absolute top-0 right-0 bg-orange p-2 lg:rounded-tr-lg rounded-bl-lg text-sm text-gray-100">
                Innovative Feature
              </p>
              <div className="flex">
                <picture>
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2024/07/Group-1868.png"
                    media="(min-width: 640px)"
                    type="image/webp"
                    alt="Vantage Perks"
                    width="200"
                  />
                  <img
                    className="z-10 relative"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/q_auto/gatsbycms/uploads/2024/07/Group-1868.png"
                    alt="Vantage Perks"
                    width="150"
                  />
                </picture>
              </div>
              <div>
                <h2 className="text-purple-100 pb-1 pt-3">
                  Deep Integration with Microsoft Teams
                </h2>
                <p className="text-purple-100 text-base">
                  With in-depth integration on Microsoft Teams, it's now easy to
                  recognize employees instantly in the flow of work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="w-full mx-auto p-10  bg-indigo-100 my-10 xl:my-20 max-w-5xl  xl:rounded-lg relative">
          <img
            decoding="async"
            loading="lazy"
            class="cta-dot-clip"
            src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"
            alt="VC white dots"
            width="90"
            height="90"
          />
          <img
            decoding="async"
            loading="lazy"
            class="cta-flower-clip"
            src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"
            alt="VC flower"
            width="150"
            height="150"
          />
          <h1 className="text-2xl lg:text-4xl text-center text-white">
            Want to <span className="text-orange">save more than 50%</span> on{" "}
            <br />
            service awards budget?
          </h1>
          <div className="mt-4 w-full flex justify-center text-center">
            <a
              href="#form"
              className="vc-new-orange-btn-rounded-full text-purple-100 lato rounded-full mt-3 md:mt-0"
            >
              Lets's Connect
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CostSaving
